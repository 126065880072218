import React, { useState } from 'react';
import './Rating.css'; // Create a CSS file for Rating component styles

const Rating = ({ id, onRatingChange }) => {
  const [selectedRating, setSelectedRating] = useState(0);

  const handleRatingClick = (value) => {
    setSelectedRating(value);
    onRatingChange(value);
  };

  return (
    <div className="rating" id={id}>
      {[1, 2, 3, 4, 5].map((value) => (
        <span
          key={value}
          className={value <= selectedRating ? 'active' : ''}
          onClick={() => handleRatingClick(value)}
        >
          &#9733;
        </span>
      ))}
    </div>
  );
};

export default Rating;
