import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBiZgZ00JrLhxvtqokFzouEvnOIsnqoo_g",
  authDomain: "feedback-form-d2b28.firebaseapp.com",
  projectId: "feedback-form-d2b28",
  storageBucket: "feedback-form-d2b28.appspot.com",
  messagingSenderId: "607727976005",
  appId: "1:607727976005:web:668fe6a3b6d73ab5612d2c",
  measurementId: "G-WQXXSJS8XY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db, collection, addDoc };
