import React, { useState } from 'react';
import { db, collection, addDoc } from './Firebase';
import Rating from './Rating';
import './App.css'; // Create a CSS file for App component styles

const App = () => {
  const [email, setEmail] = useState('');
  const [appReview, setAppReview] = useState('');
  const [webReview, setWebReview] = useState('');
  const [appRating, setAppRating] = useState(0);
  const [webRating, setWebRating] = useState(0);
  const [overallRating, setOverallRating] = useState(0);

  const handleSubmit = async () => {
    if (!email || !appReview || !webReview || !appRating || !webRating || !overallRating) {
      alert("Please fill out all fields and select ratings.");
      return;
    }

    try {
      await addDoc(collection(db, "reviews"), {
        email,
        appReview,
        appRating,
        webReview,
        webRating,
        overallRating,
        timestamp: new Date()
      });
      alert("Thank you for your feedback!");
      clearForm();
    } catch (e) {
      console.error("Error adding document: ", e);
      alert("Failed to submit feedback. Please try again later.");
    }
  };

  const clearForm = () => {
    setEmail('');
    setAppReview('');
    setWebReview('');
    setAppRating(0);
    setWebRating(0);
    setOverallRating(0);
  };

  return (
    <div className="container">
      <h2>We Value Your Feedback</h2>
      <div className="input-group">
        <label>Email:</label>
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
        />
      </div>
      <div className="input-group">
        <label>App Review:</label>
        <textarea
          value={appReview}
          onChange={(e) => setAppReview(e.target.value)}
          placeholder="Share your experience with our app"
        />
      </div>
      <div className="rating-group">
        <label>App Rating:</label>
        <Rating id="appRating" onRatingChange={setAppRating} />
      </div>
      <div className="input-group">
        <label>Web Review:</label>
        <textarea
          value={webReview}
          onChange={(e) => setWebReview(e.target.value)}
          placeholder="Share your experience with our website"
        />
      </div>
      <div className="rating-group">
        <label>Web Rating:</label>
        <Rating id="webRating" onRatingChange={setWebRating} />
      </div>
      <div className="rating-group">
        <label>Overall Rating:</label>
        <Rating id="overallRating" onRatingChange={setOverallRating} />
      </div>
      <button className="submit-btn" onClick={handleSubmit}>Submit Feedback</button>
    </div>
  );
};

export default App;
